@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
	/* font-family: "Roboto", sans-serif;
     */
	font-family: Helvetica, Arial, Sans-Serif;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.order-text {
	font-size: 18px;
}

.order-num {
	font-size: 22px;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.bg-dark.fixed-top {
	background-color: #5b9bd5 !important;
}

.app-logo {
	height: 60px;
	width: 100px;
}

.app-title {
	font: 32px Helvetica, Arial, Sans-Serif;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	width: 80%;
}

.header {
	/* font-family: "Roboto", sans-serif;
     */
	font-family: Helvetica, Arial, Sans-Serif;
}

.nav-link {
	font-size: 14px;
	text-transform: uppercase;
}

.body-content {
	margin-top: 100px;
	/* font-family: "Roboto", sans-serif;
     */
	font-family: Helvetica, Arial, Sans-Serif;
}

.card-header h5 {
	/* font: 18px "Roboto", sans-serif;
     */
	font-size: 21px;
	line-height: 21px;
	font-family: Helvetica, Arial, Sans-Serif;
	color: #2d383f;
}

.btn-default.search-btn {
	min-width: auto;
	height: 32px;
	position: relative;
	top: -1px;
	border: 1px solid #c0c0c0 !important;
}

.navbar-toggler,
.toggle-btn {
	background-color: transparent;
}

.toggle-btn[aria-expanded="true"] .fas {
	transition: 0.3s all;
	transform: rotate(-180deg);
}

.toggle-btn .fas {
	transform: rotate(0deg);
	transition: 0.3s all;
}

.modelData-tbl th,
.data-table th {
	background-color: #333f50;
	color: #fff;
	font-weight: normal;
	padding: 5px;
	text-align: center;
	vertical-align: top;
	padding: 0 10px;
}

.modelData-tbl th {
	padding: 5px 10px;
}

.modelData-tbl td,
.data-table td {
	text-align: left;
	vertical-align: top;
	padding: 0 10px;
}

.modelData-tbl .btn-primary.delete-btn {
	pointer-events: none;
	cursor: not-allowed;
	color: #aaa;
}

.modelData-tbl tr:last-child .btn-primary.delete-btn {
	cursor: pointer;
	pointer-events: initial;
	color: #000;
}

.rowNum {
	width: 10px;
}

.overflow-auto {
	overflow: auto;
}

.label {
	font-size: 16px;
	font-family: Helvetica, Arial, Sans-Serif;
	margin: 0 10px 0 0;
}

.form-label {
	margin: 0;
}

.crsr-ptr {
	cursor: pointer;
}

.form-input,
.searchbar {
	border: 1px solid #c0c0c0;
	padding: 5px;
	font-size: 14px;
	height: 32px;
	width: 100%;
}

.searchbar-row .search-label {
	float: left;
	width: 50%;
}

.searchbar-row .search-btn.btn-default {
	top: 0;
}

.search-label {
	width: 20%;
	margin-bottom: 20px;
}

.searchbar {
	border-radius: 0;
}

.form-input:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	outline: none;
}

.qtyTd,
.statusTd {
	width: 30px;
}

.error-input {
	border: 1px solid #d31245;
	background: #f0f0f0;
}

.fa-exclamation-triangle {
	color: #d31245;
}

.fa-refresh {
	font-size: 24px;
}

.text-white {
	color: #fff;
}

.btn.toggle-btn {
	padding: 0 10px;
}

.modelStringInput {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.model-string-lists {
	z-index: 1;
	background-color: #e7e6e6;
	position: relative;
	padding: 0;
	margin: 0;
	border: 1px solid #c0c0c0;
	border-top: none;
	max-height: 400px;
	overflow: auto;
	white-space: nowrap;
	min-width: 100%;
}

.model-string-lists li {
	list-style-type: none;
	font-size: 14px;
	text-align: left;
	padding: 5px;
}

.model-string-lists li:hover,
.btn-primary:not(:disabled):not(.disabled):active {
	background-color: #00aa7e;
	cursor: pointer;
	color: #fff;
	border: 1px solid #00aa7e;
}


/* .btn-primary.add-modalBtn {
     display: none !important;
}
 */

.modifier-list {
	min-width: 130px;
	/* max-width: 150px;
     */
	;
}

.code-input {
	border: none;
	border-bottom: 1px solid #ccc;
}

.code-input:focus,
.btn:focus,
.toggle-btn:focus {
	outline: none;
}

.toggle-btn.btn:focus,
.btn-danger:focus,
.btn-danger.focus {
	box-shadow: none;
}

.code-input::placeholder {
	font-weight: bold;
}

.code-input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-weight: bold;
}

.code-input::-ms-input-placeholder {
	/* Microsoft Edge */
	font-weight: bold;
}


/***DATA TABLE*****/

.minWidth-auto {
	min-width: auto !important;
}

.data-table,
.modelData-tbl {
	border-collapse: separate;
	border-spacing: 0 10px;
}

.data-table td {
	padding: 5px;
}

.data-table th {
	padding: 5px;
	text-align: left;
}

.error-text {
	font-size: 14px;
	border-radius: 0;
	padding: 5px;
	z-index: 1;
}

.error-tooltip {
	position: relative;
	display: inline-block;
}

.error-tooltip .tooltiptext {
	visibility: hidden;
	min-width: 150px;
	background-color: #fff;
	color: #d31245;
	text-align: left;
	border-radius: 6px;
	padding: 10px;
	border: 1px solid #d31245;
	position: absolute;
	z-index: 1;
	font-size: 12px;
}

.error-tooltip:hover .tooltiptext {
	visibility: visible;
}

.tooltip-right {
	top: -5px;
	left: 135%;
}

.tooltip-right::after {
	content: "";
	position: absolute;
	top: 15%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent #d31245 transparent transparent;
}

.btn-primary,
.btn-danger,
.btn-default {
	min-width: 166px;
	height: 25px;
	background: transparent;
	border: 1px solid #101010;
	color: #000;
	vertical-align: middle;
	text-transform: uppercase;
	border-radius: 0;
	font-size: 14px;
	padding-top: 0;
	padding-bottom: 0;
}

.btn-danger.delete-btn {
	cursor: not-allowed !important;
	pointer-events: none;
	background-color: #ddd;
}

.modelData-tbl tr:last-child .btn-danger.btn-danger.delete-btn {
	cursor: pointer !important;
	pointer-events: inherit;
	background-color: transparent;
}


/* .save-btn */

.btn-primary:hover,
.btn-danger:hover,
.btn-default:hover {
	background-color: #00aa7e !important;
	cursor: pointer;
	color: #fff;
	border: 1px solid #00aa7e;
}

.btn-primary.add-btn:hover,
.btn-primary.delete-btn:hover,
.btn-primary.add-btn:focus,
.btn-primary.delete-btn:focus,
.btn-primary.add-btn:not(:disabled):not(.disabled):active:focus,
.btn-primary.edit-btn:not(:disabled):not(.disabled):active:focus,
.btn-primary.delete-btn:not(:disabled):not(.disabled):active:focus {
	background-color: transparent !important;
	outline: none;
	box-shadow: none;
}

.btn-primary.transparent:hover {
	background-color: transparent !important;
}

.btn-primary.add-btn:hover i,
.btn-primary.edit-btn:hover i {
	color: #00aa7e;
}

.btn-primary.delete-btn:hover i {
	color: #dc3545;
}

.serialNum {
	width: 60px;
}

.ok-btn {
	width: 70px;
	min-width: auto;
	background-color: #eee;
	float: right;
	height: 25px;
	text-transform: uppercase;
}

.save-btn,
.newRow-btn {
	height: 32px;
}

.fa-pen {
	cursor: pointer;
}

.quantity-col {
	width: 40px;
}

.warehouse-col,
.worldArea-col {
	max-width: 230px;
	width: 210px;
}

.addRow-btn {
	min-width: auto;
	border-radius: 50%;
	height: auto;
	width: auto;
	padding: 2px 5px;
}

.status-col {
	width: 50px;
}

.status-col .btn-primary {
	border: 0;
	min-width: auto;
}

.status-col .btn-primary:hover {
	background-color: transparent !important;
	cursor: auto;
}

.status-col .btn-primary i {
	font-size: 20px;
	cursor: pointer;
}

.fa-exclamation-circle {
	color: #ffc107;
}

/* .close {
	display: none;
} */

.required-icon {
	position: absolute;
	right: -2px;
	font-size: 12px;
	top: -5px;
}

.required-icon.seqNo {
	left: -2px;
}

.status-col .fa-times-circle-o,
.fa-times-circle-o {
	color: #dc3545;
}

.fa-times-circle-o,
.fa-check-circle-o,
.fa-exclamation-circle {
	font-size: 18px;
	cursor: pointer;
}

.fa-check-circle-o {
	color: #00aa7e;
}

.fa-plus {
	padding: 4px 5px;
}

.fa-plus-circle,
.fa-trash-o {
	font-size: 24px;
}

.floating-btns {
	position: fixed;
	top: 50%;
	right: -70px;
	z-index: 1;
}

.floating-btns i {
	font-size: 24px;
	padding: 0 7px;
}

.floating-btns .btn-default {
	min-width: 110px;
	padding: 5px;
	font-size: 16px;
	height: auto;
	text-align: left;
}

.floating-btns .clear-btn,
.floating-btns .save-btn,
.floating-btns .paste-btn {
	position: relative;
	right: 0;
	background-color: #eee;
}

.floating-btns .quick-btn {
	position: relative;
	right: 0;
	padding-left: 12px;
	background-color: #eee;
}

.floating-btns .clear-btn:hover,
.floating-btns .save-btn:hover,
.floating-btns .paste-btn:hover,
.floating-btns .quick-btn:hover {
	right: 70px;
	background-color: #00aa7e;
}

.floating-btns .btn:disabled {
	cursor: not-allowed !important;
}

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* height: 60px;
     line-height: 60px;
     */
	background-color: #f5f5f5;
	padding: 10px;
	text-align: right;
	font-size: 14px;
}

.react-bootstrap-table table {
	table-layout: auto;
}

.grouped-table {
	min-height: 50px;
	overflow: auto;
	max-height: 400px;
}


/* .row-expansion-style {
     max-height: 400px;
     overflow: auto;
} */

#pageDropDown {
	min-width: auto !important;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
	min-width: auto;
	padding: 0;
	border-radius: 0;
}

.react-bs-table-sizePerPage-dropdown .dropdown-item {
	padding: 0;
}

.react-bs-table-sizePerPage-dropdown .dropdown-item:hover {
	background-color: #00aa7e;
	cursor: pointer;
}

.react-bs-table-sizePerPage-dropdown .dropdown-item a {
	color: #000;
	text-decoration: none;
	padding: 5px 15px;
	display: block;
}

.react-bs-table-sizePerPage-dropdown .dropdown-item:hover a {
	color: #fff;
}

.pagination.react-bootstrap-table-page-btns-ul {
	float: right;
	margin-right: 10%;
	font-size: 13px;
	font-weight: 700;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
	border-radius: 0;
}

.page-item a {
	margin: 0 2px 0 0;
	padding: 7px 10px;
	color: #111a20;
}

.page-item.active .page-link {
	background: #00aa7e;
	border: 1px solid #00aa7e;
	color: #fff;
}

.page-link:hover {
	background-color: transparent;
	border: 1px solid #00aa7e;
	text-decoration: underline;
	color: #111a20;
}

.page-link:focus {
	box-shadow: none;
}

.react-bootstrap-table .table th,
.react-bootstrap-table .table td {
	white-space: nowrap;
	font-size: 14px;
	font-weight: normal;
	padding: 10px;
	text-align: center;
	vertical-align: top;
}

.react-bootstrap-table .table th {
	background-color: #333f50;
	color: #fff;
}

.react-bootstrap-table-pagination-total {
	margin: 0 10px;
	font-size: 14px;
	font-family: Helvetica, Arial, Sans-Serif;
}

.save-table-btn {
	position: relative;
	top: -40px;
}

.submit-btn.btn-primary.disabled {
	background-color: #ddd;
	border-color: #ddd;
	color: #000;
}

.submit-btn.btn-primary.disabled:hover {
	background-color: #ddd !important;
	border-color: #ddd;
	cursor: not-allowed;
}

.row.accordian-row {
	margin: 90px 0 0 0;
}

.Collapsible {
	background-color: white;
	margin-bottom: 8px;
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: .25rem;
}

.Collapsible__contentInner {
	padding: 10px;
	border: 1px solid #ebebeb;
	border-top: 0;
}

.Collapsible__contentInner p {
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 20px;
}

.Collapsible__contentInner p:last-child {
	margin-bottom: 0;
}

.Collapsible__trigger {
	display: block;
	font-weight: 400;
	font-size: 20px;
	text-decoration: none;
	color: #333333;
	position: relative;
	border: 1px solid white;
	padding: 10px;
	background: rgba(0, 0, 0, .03);
	border-bottom: 1px solid rgba(0, 0, 0, .125);
	color: #000000;
}

.Collapsible__trigger:after {
	font-family: 'FontAwesome';
	content: '\f107';
	position: absolute;
	right: 10px;
	top: 10px;
	display: block;
	transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
	transform: rotateZ(180deg);
}

.Collapsible__trigger.is-open+.Collapsible__contentOuter {
	overflow: initial !important;
}

.Collapsible__trigger.is-disabled {
	opacity: 0.5;
	background-color: grey;
}

.CustomTriggerCSS {
	background-color: lightcoral;
	transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
	background-color: darkslateblue;
}

.Collapsible__custom-sibling {
	padding: 5px;
	font-size: 12px;
	background-color: #CBB700;
	color: black;
}

.tab-content {
	margin: 25px 0px 0px 0px;
}

.spinner-block {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	transition: opacity .15s linear;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
}

#loaderStyle {
	position: absolute;
	bottom: 50%;
	right: 50%;
	transform: translate(50%, 50%);
}

.position-relative #loaderStyle {
	top: -8px;
}

.status-loader.line-status .loader {
	height: 25px;
	width: 25px;
	top: 8px;
}

.Collapsible__contentInner {
	border: none;
}

.fa-pencil {
	font-size: 18px;
}

.enteredData-tbl {
	max-height: 500px;
	overflow: auto;
}

.table-hover .table-splited tbody tr:hover {
	background-color: transparent;
}

.enteredData-tbl>.react-bootstrap-table .table td {
	white-space: inherit;
}

.enteredData-tbl>.react-bootstrap-table>table td:first-child,
.enteredData-tbl>.react-bootstrap-table>table th:first-child {
	width: 30px;
}

.enteredData-tbl>.react-bootstrap-table>table td:first-child {
	padding: 10px 0;
}

.enteredData-tbl>.react-bootstrap-table>table th:nth-last-child(2),
.enteredData-tbl>.react-bootstrap-table>table td:nth-last-child(2),
.enteredData-tbl>.react-bootstrap-table>table th:nth-last-child(3),
.enteredData-tbl>.react-bootstrap-table>table td:nth-last-child(3) {
	display: none;
}

.enteredData-tbl>.react-bootstrap-table>table th,
.enteredData-tbl>.react-bootstrap-table>table td {
	border-bottom: none;
}

.submitted-datatable>.react-bootstrap-table>table th:nth-last-child(2),
.submitted-datatable>.react-bootstrap-table>table td:nth-last-child(2),
.submitted-datatable>.react-bootstrap-table>table th:nth-last-child(3),
.submitted-datatable>.react-bootstrap-table>table td:nth-last-child(3),
.submitted-datatable>.react-bootstrap-table>table th:nth-last-child(4),
.submitted-datatable>.react-bootstrap-table>table td:nth-last-child(4) {
	display: table-cell;
}

.submitted-datatable table th:nth-last-child(3),
.submitted-datatable table td:nth-last-child(3) {
	padding: 10px 0;
}

.submitted-datatable table tr th:last-child,
.submitted-datatable table tr td:last-child {
	display: none;
}

.refresh-icon i:hover {
	cursor: pointer;
	color: #00aa7e;
}

.pricing-container {
	border: #ddd 1px solid;
	padding: 5px 0px 10px 0px;
	border-radius: 3px;
	background: #f5f5f5;
}

.pricing-perIcon {
	right: -5px;
	top: 5px;
}

.menu-item {
	padding: 0 30px;
	margin: 5px 4px;
	user-select: none;
	cursor: pointer;
	border: none;
}

.menu-item-wrapper.active {
	/* border: 1px blue solid; */
	border: 1px solid grey;
	border-bottom: none;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem;
	color: #72A6FF;
}

.menu-item.active {
	border: 1px green solid;
}

.scroll-menu-arrow {
	padding: 20px;
	cursor: pointer;
}

.nav-link {
	cursor: pointer;
}

input[type=checkbox] {
	height: 18px;
}

.urd-section .form-label {
	word-break: break-all;
}

.mandatory {
	color: red;
}

.base-input select:disabled {
	cursor: not-allowed;
}

.base-input .form-input {
	width: 50%;
}

.rwt__tabpanel {
	display: block !important;
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.rwt__tab {
	text-align: left;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
	border-right: 3px solid #333f50 !important;
}

.parentHeading {
	width: max-content;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	opacity: 1;
}

.urd-labels .form-label {
	float: none;
	min-width: 48%;
	margin: 5px 0;
}

.urd-labels .form-input {
	width: 50%;
	margin: 5px 0;
}

.vortex-section.urd-labels .form-label {
	min-width: 30%;
}

.vortex-section.urd-labels .form-input {
	width: 38%;
	margin: 5px;
}

.vortex-section .tagsDiv {
	float: left;
	width: 50%;
}

.vortex-section.urd-labels > div {
	float: left;
	width: 48%;
}

.frame {
	border-bottom: 1px solid lightgray;
	padding: 5px 0;
	margin: 10px 0;
}

.verticalTab {
	display: contents;
}

.down {
	cursor: pointer;
}

.rwt__tablist[aria-orientation="vertical"] {
	border-left: 1px solid #ddd;
	border-top: 1px solid #ddd;
}

.rwt__tab[aria-selected="true"] {
	background-color: #E8E8E8;
}

.loader-block {
	position: absolute;
	bottom: 50%;
	right: 50%;
	transform: translate(50%, 50%);
}

.loader {
	border: 10px solid #f3f3f3;
	border-radius: 50%;
	border-top: 10px solid #00aa7e;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}


/* Safari */

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.status-loader .loader {
	width: 30px;
	height: 30px;
	border: 5px solid #ccc;
	border-top: 5px solid #00aa7e;
}

.rwt__tab {
	display: block;
	width: 100%;
}

.topLine {
	border-top: 1px solid lightgray;
	margin-top: 10px;
	padding-top: 7px;
}

.drawer .backdrop,
.drawer .right {
	position: fixed;
	top: 0;
	right: -100%;
	bottom: 0;
	height: 100%;
	width: 100%;
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	-webkit-z-index: 1040;
	-moz-z-index: 1040;
	-ms-z-index: 1040;
	-o-z-index: 1040;
	z-index: 1040;
}

.drawer .backdrop {
	display: none;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	-webkit-z-index: 1035;
	-moz-z-index: 1035;
	-ms-z-index: 1035;
	-o-z-index: 1035;
	z-index: 1035;
}

.drawer.open,
.drawer.open .backdrop {
	right: 0;
	display: block;
}

.drawer .right {
	right: -50%;
	background-color: white;
	height: 100%;
	width: 50%;
	box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	-webkit-z-index: 1045;
	-moz-z-index: 1045;
	-ms-z-index: 1045;
	-o-z-index: 1045;
	z-index: 1045;
}

.drawer tbody {
	display: block;
	overflow-y: scroll;
	max-height: 85vh;
}

.drawer tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.drawer.open .right.expanded {
	width: 60%;
}

.center {
	text-align: center!important;
}

.drawer .children {
	margin: 8px;
	padding: 16px;
}

.drawer.open .right {
	right: 0;
}

.additionalInfo-field {
	margin-bottom: 10px;
}

.additionalInfo-field label {
	margin-top: 6px;
}

.ui.fluid.dropdown {
	border: 1px solid #c0c0c0;
	border-radius: 0;
}

input[type=text] {
	padding: 3px;
}

.react-datepicker-ignore-onclickoutside {
	padding: 3px !important;
}

table.pasteOption tbody tr {
	position: relative;
}

table.pasteOption tbody tr td input {
	text-align: center;
}

table.pasteOption tbody tr td input[type='checkbox'] {
	position: absolute;
	top: 12px;
	left: 0;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:disabled:hover {
	background: grey!important;
	border: none;
}

.mass-adj-label {
	width: 25%;
	background: #eee;
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 5px 0px 0px 5px;
}

.mass-adj-value {
	width: 75%;
	background: #ddd;
	padding: 2px 2px 2px 0px;
	border-radius: 0px 5px 5px 0px;
}

.mass-adj-control {
	border-radius: 0px 5px 5px 0px;
}

.mass-adj-header {
	background: #eee;
	padding: 10px;
}

.mass-adj-header>h4 {
	font-size: 18px;
}

.mass-adj-footer {
	padding: 10px;
}

.mass-adj-close {
	display: block;
	outline: none !important;
}

.mass-adj-modal {
	background: rgba(0, 0, 0, 0.5) !important;
	display: block;
}

.mass-adj-modal-dialog {
	max-width: 80% !important;
}

.react-tabs__tab {
	display: block !important;
	bottom: 0 !important;
}

.react-tabs__tab-list {
	border: 1px solid #ddd;
	border-bottom: none !important;
}

.react-tabs__tab--selected {
	background: #00aa7e!important;
	color: #fff!important;
	border-radius: 0 !important;
}

.react-tabs__tab:hover {
	background: #00aa7e;
	color: #fff;
	cursor: pointer;
}

.booleanClass input[type=checkbox] {
	width: 2% !important;
	height: 20px;
}

.text-underline {
	text-decoration: underline;
}

.AdditionalButton {
	margin-top: -45px;
	margin-right: 12px;
}

.fa-chevron-down.disabled {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.add-btn.btn-primary:disabled {
	background: transparent !important;
}

.add-btn.btn-primary:disabled i {
	color: gray;
}

.cancel-btn.disabled,
.refresh-icon.disabled {
	cursor: not-allowed;
	color: #000;
	pointer-events: none;
}

.refresh-icon.disabled i {
	color: #bbb;
}

.windowClosePopup {
	pointer-events: none;
}

a.preference-link {
	color: #007bff !important;
	text-decoration: underline !important;
	font-size: 14px !important;
	cursor: pointer;
}

#reactjs-input-validator label {
	font-weight: 600;
}

.help-block {
	color:#dc3545;
}

.notifyCheck {
	position: relative;
	top: 5px;
	margin-right: 4px;
}

.multi-level-check {
	padding: 6px 0px 0px 15px;
}

.select-loader {
	height: 30px;
	width: 30px;
	border: 5px solid #f3f3f3;
	border-top: 5px solid #00aa7e;
	margin-left: 5px;
}

.float-left {
	float: left !important;
}

.dropdown-icon {
	width: 30px;
    height: 30px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2px 0px 0px -30px;
}

.table-body-scroll > tbody {
	display: block;
	max-height: 410px;
	overflow-y: scroll;
}
  
.table-body-scroll > thead,
.table-body-scroll > tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.urdNoData{
	margin-left: 34%;
    font-style: italic;
}

.searchbar::-ms-clear {
    display: none;
}

.value-list {
	top: 32px;
    width: 100%;
    position: relative;
}

.value-section {
	width: 50% !important;
}

.ddr-selected {
	background: steelblue;
    color: white;
}

.no-text-selection {
	user-select: none;
}

.ddr-item:hover {
	border: none !important;
}

.submitNote{
	font-size: 14px;
    background-color: lightblue;
    margin-left: 19px;
    margin-top: -25px;
    padding: 4px;
}

.SubmitConfirmation{
	margin-left: 19px;
    font-size: 15px;
}